import { useTranslation } from 'react-i18next';

import { PRIZE_POOL_PERCENTAGE, QUICK_MATCH_MAX_PLAYERS } from '@hf/shared-common';

import { CoinIcon } from '@shared/assets';
import { useCountdown } from '@shared/hooks/utils/useCountdown';
import { useUtils } from '@shared/hooks/utils/useUtils';
import {
  EMatchButtons,
  EMatchModals,
  selectCountUsersWaitingRoom,
  selectDisabledMatchButton,
  selectLoadingMatchButton,
  selectMatchBet,
  selectViewMatchModal,
  setDisabledMatchButtons,
} from '@shared/store/slice/MatchSlice';
import { useAppDispatch, useAppSelector } from '@shared/store/store';
import { Button, LoaderWaves } from '@shared/ui';
import { NBSP, getBoolean, sleep } from '@shared/utils/helpers.utils';
import { Modal } from '@shared/widgets';

interface IProps {
  confirm: () => void;
  disconnect: () => void;
}
const isEnableButtonConfirm = !getBoolean(import.meta.env.VITE_APP_ENABLE_AUTO_CONFIRM_JOIN_ROOM_FAST_MP, true);

export const FastMatchWaitingRoomModal = ({ confirm, disconnect }: IProps) => {
  const dispatch = useAppDispatch();

  const { setTargetDate, timeLeft } = useCountdown(async () => {
    await sleep(2000);
    dispatch(setDisabledMatchButtons({ button: EMatchButtons.disconnect, value: false }));
  });
  const { t } = useTranslation();
  const { getBalanceFormat } = useUtils();
  const isBotWaiting = getBoolean(import.meta.env.VITE_APP_ENABLE_BOT_WAITING_FAST_MP, true);
  const isOpen = useAppSelector((state) => selectViewMatchModal(state, EMatchModals.waitingRoom));
  const isLoadingJoinRoom = useAppSelector((state) => selectLoadingMatchButton(state, EMatchButtons.joinRoom));
  const isDisabledJoinRoom = useAppSelector((state) => selectDisabledMatchButton(state, EMatchButtons.joinRoom));
  const isDisabledDisconnect = useAppSelector((state) => selectDisabledMatchButton(state, EMatchButtons.disconnect));
  const bet = useAppSelector(selectMatchBet);

  const countUsers = useAppSelector(selectCountUsersWaitingRoom);

  const confirmHandler = () => {
    if (isLoadingJoinRoom || isDisabledJoinRoom) return;
    confirm();
  };
  const disconnectHandler = () => {
    if (isDisabledDisconnect || isDisabledDisconnect) return;
    disconnect();
  };

  return (
    <>
      <Modal
        isOpen={isOpen}
        title={t('multiplayer:modal.matchWaiting.title')}
        isButtonClose={false}
        className="flex flex-col gap-[10px]"
      >
        <span className="flex justify-center font-bold">
          {t('global:prizePool')}
          {NBSP}
          {getBalanceFormat(bet * QUICK_MATCH_MAX_PLAYERS * PRIZE_POOL_PERCENTAGE)}
          {NBSP}
          <CoinIcon width={18} height={20} />
        </span>
        <span className="flex justify-center font-bold">
          {t('global:yourBet')}
          {NBSP}
          {getBalanceFormat(bet)}
          {NBSP}
          <CoinIcon width={18} height={20} />
        </span>

        {isBotWaiting ? (
          <>
            <span className="mx-auto font-bold text-[16px] animate-pulseButton">{t('global:waitingPlayers')}</span>
            <LoaderWaves className="absolute left-0 bottom-0 right-0 z-[-1] opacity-50" fill="#5b4c98" />
          </>
        ) : (
          <span className="text-center">
            {t('global:playersReady')} {countUsers}/{QUICK_MATCH_MAX_PLAYERS}
          </span>
        )}

        {isEnableButtonConfirm && (
          <Button
            size="lg"
            theme={!isDisabledJoinRoom ? 'green' : 'purple'}
            loading={isLoadingJoinRoom}
            disabled={isDisabledJoinRoom}
            className={`!text-[13px] ${!isDisabledJoinRoom ? 'animate-bounceAndScale' : ''}`}
            onClick={confirmHandler}
          >
            {isDisabledJoinRoom
              ? `${t('global:waitingPlayers')} ${timeLeft ? `- ${timeLeft}` : ''} `
              : `${t('global:joinRoom')}  ${timeLeft ? `- ${timeLeft}` : ''}`}
          </Button>
        )}

        <Button size="sm" theme="red" disabled={isDisabledDisconnect} onClick={disconnectHandler}>
          {t('global:disconnect')}
        </Button>
      </Modal>
    </>
  );
};
