import React from 'react';

interface IProps {
  className?: string;
  classNameContent?: string;
  progress: number;
  text?: React.ReactNode | string;
  size?: 'sm' | 'lg';
}

export const ProgressBar = ({ className, classNameContent, progress, size = 'sm', text }: IProps) => {
  const getSizeStyle = (): { sizeStyle: string } => {
    switch (size) {
      case 'lg':
        return { sizeStyle: 'h-[0.8rem]' };
      case 'sm':
        return { sizeStyle: 'h-[0.4rem]' };
    }
  };
  const { sizeStyle } = getSizeStyle();

  return (
    <div className={`bg-white/20 rounded-10  w-full overflow-hidden ${sizeStyle} ${className ?? ''}`}>
      <span
        className={`bg-primary-purpleLight block h-full rounded-[0_0.4rem_0.4rem_0] ${classNameContent ?? ''}`}
        style={{ width: `${progress}%`, transition: 'width 0.2s ease-in-out' }}
      >
        {text}
      </span>
    </div>
  );
};
