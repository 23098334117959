import { useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { NavLink } from 'react-router-dom';
import SlotCounter from 'react-slot-counter';
import { animated } from 'react-spring';

import { AmplitudeEventClient, QUICK_MATCH_MAX_PLAYERS } from '@hf/shared-common';

import * as amplitude from '@amplitude/analytics-browser';
import { EAppRouterUrl } from '@app/AppRouter';
import { MatchLeaveTournament } from '@pages/games/multiplayer/ui/MatchLeaveTournament';
import { ConfirmationRoundModal } from '@pages/games/multiplayer/widgets/ConfirmationRoundModal/ConfirmationRoundModal';
import { FastMatchChoosingBetModal } from '@pages/games/multiplayer/widgets/FastMatchChoosingBetModal/FastMatchChoosingBetModal';
import { FastMatchWaitingRoomModal } from '@pages/games/multiplayer/widgets/FastMatchWaitingRoomModal/FastMatchWaitingRoomModal';
import { MatchLastCoefficient } from '@pages/games/multiplayer/widgets/MatchLastCoefficient/MatchLastCoefficient';
import { RemainingBaitsIcon } from '@shared/assets';
import { useAnimateSpring } from '@shared/hooks/integration/useAnimateSpring';
import { useMatchActions } from '@shared/hooks/match/useMatchActions';
import { useMatchCore } from '@shared/hooks/match/useMatchCore';
import { useUtils } from '@shared/hooks/utils/useUtils';
import {
  selectGameButtonParams,
  selectGameButtonResults,
  selectShowGameButton,
  setShowGameHeader,
} from '@shared/store/slice/CommonSlice';
import {
  EMatchButtons,
  EMatchModals,
  selectCountUsersGameRoom,
  selectCurrentRound,
  selectDisabledMatchButton,
  selectFastMatchPing,
  selectIsShowHeaderInfoTournament,
  selectIsShowInfoMatch,
  selectIsShowLobbyButtons,
  selectLastCoefficients,
  selectLifeTable,
  selectLoadingMatchButton,
  selectMatchId,
  selectRemainingBaits,
  selectWaitTimeNextRound,
  setViewMatchModals,
} from '@shared/store/slice/MatchSlice';
import { useAppDispatch, useAppSelector } from '@shared/store/store';
import { Button, GameButton, Indicator, ProgressBar, Wrapper } from '@shared/ui';
import { GameButtonResult } from '@shared/ui/GameButton/GameButtonResult';
import { NBSP } from '@shared/utils/helpers.utils';

import { MatchLifeLeaderboard } from './widgets/MatchLifeLeaderboard/MatchLifeLeaderboard';

export const FastMatchPage = () => {
  const dispatch = useAppDispatch();

  const [progress, setProgress] = useState(100);
  const frameId = useRef<number>(0);
  const start = useRef<number | null>(null);
  const prevProgress = useRef<number>(100);
  const { toClick } = useUtils();
  const { t } = useTranslation();
  const { useAnimTranslateX, useAnimTranslateY } = useAnimateSpring();
  const {
    initGame,
    findLobby,
    confirmJoinRoom,
    destroyScene,
    processingGameButton,
    onDisconnectWaitingRoomHandler,
    leaveTournament,
    confirmNextRound,
  } = useMatchCore();
  const lifeTable = useAppSelector(selectLifeTable);
  const gameButtonParams = useAppSelector(selectGameButtonParams);
  const gameButtonResults = useAppSelector(selectGameButtonResults);
  const countUsersGameRoom = useAppSelector(selectCountUsersGameRoom);
  const currentRound = useAppSelector(selectCurrentRound);
  const waitTimeNextRound = useAppSelector(selectWaitTimeNextRound);
  const isShowInfo = useAppSelector(selectIsShowInfoMatch);
  const lastCoefficients = useAppSelector(selectLastCoefficients);
  const isShowHeaderInfoTournament = useAppSelector(selectIsShowHeaderInfoTournament);
  const isShowLobbyButtons = useAppSelector(selectIsShowLobbyButtons);
  const showButton = useAppSelector(selectShowGameButton);
  const remainingBaits = useAppSelector(selectRemainingBaits);
  const { closeFastMatchPage } = useMatchActions();
  const isDisabledReturnHome = useAppSelector((state) => selectDisabledMatchButton(state, EMatchButtons.returnHome));
  const isLoadingFindLobby = useAppSelector((state) => selectLoadingMatchButton(state, EMatchButtons.findLobby));
  const isDisabledFindLobby = useAppSelector((state) => selectDisabledMatchButton(state, EMatchButtons.findLobby));
  const showInfoTournamentStyle = useAnimTranslateY(isShowHeaderInfoTournament);
  const gameId = useAppSelector(selectMatchId);
  const ping = useAppSelector(selectFastMatchPing);

  const onFindLobbyHandlerHandler = () => {
    if (isDisabledFindLobby || isLoadingFindLobby) return;
    dispatch(setViewMatchModals({ modal: EMatchModals.choosingBet, value: true }));
  };

  const onLeaveTournamentHandler = () => {
    leaveTournament();
  };

  useEffect(() => {
    amplitude.track(AmplitudeEventClient.FastMultiplayerOpened);
    initGame();
    return () => {
      destroyScene();
      closeFastMatchPage();
    };
  }, []);

  useEffect(() => {
    if (!waitTimeNextRound) {
      setTimeout(() => setProgress(100), 200);
      return;
    }

    const step = (timestamp: number) => {
      if (!start.current) start.current = timestamp;
      const remainsTime = timestamp - start.current;
      const newProgress = Math.floor(Math.max(100 - (remainsTime / waitTimeNextRound) * 100, 0));

      if (remainsTime < waitTimeNextRound && newProgress > 0) {
        if (newProgress !== prevProgress.current) {
          setProgress(newProgress);
          prevProgress.current = newProgress;
        }
        frameId.current = window.requestAnimationFrame(step);
      } else {
        setProgress(0);
        prevProgress.current = 100;
        start.current = null;
        window.cancelAnimationFrame(frameId.current);
      }
    };

    frameId.current = window.requestAnimationFrame(step);

    return () => {
      window.cancelAnimationFrame(frameId.current);
    };
  }, [waitTimeNextRound]);

  return (
    <>
      <div className="flex h-full w-full flex-col gap-y-[10px] relative overflow-hidden">
        {isShowHeaderInfoTournament && (
          <animated.div
            style={showInfoTournamentStyle}
            className={`w-full flex flex-col items-center gap-y-[5px] pt-[5px]`}
          >
            <div className="flex justify-between w-full text-[10px]">
              <span>Game id: {gameId}</span>
              <div className="flex items-center">
                <Indicator className="inline-flex" theme={ping.theme} />
                <SlotCounter value={ping.ms} sequentialAnimationMode useMonospaceWidth autoAnimationStart={false} />
                {NBSP}ms
              </div>
            </div>
            <MatchLeaveTournament onClick={onLeaveTournamentHandler} />
            <MatchLastCoefficient list={lastCoefficients} />
            <MatchLifeLeaderboard
              playersReady={{ current: countUsersGameRoom, max: QUICK_MATCH_MAX_PLAYERS }}
              roundLeft={currentRound}
              list={lifeTable.list}
              me={lifeTable.me}
            />
          </animated.div>
        )}

        <animated.div style={useAnimTranslateX(isShowInfo)} className="flex flex-col gap-y-[10px]">
          <Wrapper title={t('multiplayer:fastMatch.title')} theme="darkBlue" />
          <Wrapper className="flex flex-col gap-y-[20px]" theme="darkBlue">
            <div className="flex flex-col gap-y-[10px] px-[14px]">
              <span>{t('multiplayer:fastMatch.howToPlay.title')}</span>
              <ul className="list-disc">
                <li>{t('multiplayer:fastMatch.howToPlay.li_1')}</li>
                <li>
                  {t('multiplayer:fastMatch.howToPlay.li_2')}
                  <ul className="list-dash pl-2">
                    <li>
                      {NBSP} {t('multiplayer:fastMatch.howToPlay.li_2_1')}
                    </li>
                    <li>
                      {NBSP} {t('multiplayer:fastMatch.howToPlay.li_2_2')}
                    </li>
                    <li>
                      {NBSP} {t('multiplayer:fastMatch.howToPlay.li_2_3')}
                    </li>
                  </ul>
                </li>
                <li>{t('multiplayer:fastMatch.howToPlay.li_3')}</li>
              </ul>
            </div>
          </Wrapper>
        </animated.div>
        <animated.div
          style={useAnimTranslateY(isShowLobbyButtons)}
          className="h-full flex flex-col gap-y-[10px] justify-end"
        >
          <Button
            size="lg"
            onClick={onFindLobbyHandlerHandler}
            loading={isLoadingFindLobby}
            disabled={isDisabledFindLobby}
            sound={{ disabled: true }}
          >
            {t('global:findLobby')}
          </Button>
          <NavLink
            to={EAppRouterUrl.fishing}
            onClick={(event) =>
              toClick(event, EAppRouterUrl.fishing, {
                disabled: isDisabledReturnHome,
                action: () => {
                  dispatch(setShowGameHeader(false));
                },
              })
            }
          >
            <Button theme="gray" className="w-full" disabled={isDisabledReturnHome} sound={{ disabled: true }}>
              {t('global:returnHome')}
            </Button>
          </NavLink>
        </animated.div>

        {showButton && (
          <div className="fixed  bottom-[25px] left-0 right-0 mx-auto flex flex-col items-center">
            <ProgressBar
              progress={progress}
              className={`mb-[10px] max-w-[200px] h-[10px] transition-opacity ${!waitTimeNextRound ? 'opacity-0' : ''}`}
              classNameContent="bg-primary-blueExtraDark"
            />
            <GameButton
              pulse={gameButtonParams.pulse}
              view={showButton}
              mode={gameButtonParams.mode}
              text={gameButtonParams.text}
              disabled={gameButtonParams.disabled}
              loading={gameButtonParams.loading}
              showExtraContent={gameButtonResults.show}
              onClick={processingGameButton}
            >
              <GameButtonResult
                yourCatch={gameButtonResults.catch}
                coins={gameButtonResults.coins}
                fishes={gameButtonResults.fishes}
              />
            </GameButton>
            <div>
              <span className="text-white font-bold text-[26px] flex items-center justify-center gap-x-[5px]">
                <RemainingBaitsIcon width={26} height={26} />
                <span>{remainingBaits}</span>
              </span>
            </div>
          </div>
        )}
      </div>
      <FastMatchChoosingBetModal confirm={findLobby} />
      <FastMatchWaitingRoomModal confirm={confirmJoinRoom} disconnect={onDisconnectWaitingRoomHandler} />
      <ConfirmationRoundModal
        confirmNextRound={confirmNextRound}
        showProgress={!!waitTimeNextRound}
        progress={progress}
      />
    </>
  );
};
